import React from 'react';
import './ContentSection.css';
import '../App.css'
import Button from "./Button";

const ContentSection = ({ imageSrc, heading, paragraphs, buttons, alt = [], imageRight }) => {
    return (
        <section className={`content-padding content-section ${imageRight ? 'image-right' : 'image-left'}`}>
            <div className="content-image">
                <img loading="lazy" src={imageSrc} alt={alt} />
            </div>
            <div className="content-text">
                <h2>{heading}</h2>
                {paragraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
                <div className="button-group">
                    {buttons.map((button, index) => (
                        <Button key={index} link={button.link} text={button.text} />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ContentSection;