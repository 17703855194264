import '../App.css';
import React, {useState} from 'react';
import Button from "../components/Button";
import copyImg from "../assets/copy.webp"
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MAX_FILE_SIZE_MB = 5; // Max file size in MB
const steps = ['Kontakt', 'Meldungsdaten', 'Über die Schildkröte'];

const ProgressBar = ({step}) => {
    return (
        <div className="progress-bar">
            {steps.map((label, index) => {
                const stepNumber = index + 1;
                const isActive = stepNumber <= step;
                const isCompleted = stepNumber < step;
                return (
                    <div key={index} className={`step ${isActive ? 'active' : ''} ${isCompleted ? 'completed' : ''}`}>
                        <div className="circle">{stepNumber}</div>
                        <div className="label">{label}</div>
                    </div>
                );
            })}
        </div>
    );
};

const validationSchemas = [
    Yup.object({
        firstName: Yup.string().required('Pflichtfeld'),
        lastName: Yup.string().required('Pflichtfeld'),
        email: Yup.string().email('Invalid email address').required('Pflichtfeld'),
    }),
    Yup.object({
        reportType: Yup.string().required('Pflichtfeld'),
        date: Yup.date().required('Pflichtfeld'),
        location: Yup.string().required('Pflichtfeld'),
    }),
    Yup.object({
        turtleType: Yup.string().required('Pflichtfeld'),
        size: Yup.number().required('Pflichtfeld').min(0, 'ungültige Größe'),
        weight: Yup.number().required('Pflichtfeld').min(0, 'ungültiges Gewicht'),
        agree: Yup.boolean()
            .required('Der Datenschutzerklärung und der Verarbeitung der Daten muss zugestimmt werden.')
            .oneOf([true], 'Der Datenschutzerklärung und der Verarbeitung der Daten muss zugestimmt werden.'),
    }),
];

const ContactForm = () => {
    const [fileErrors, setFileErrors] = useState({});
    const [currentStep, setCurrentStep] = useState(1); // State für aktuellen Schritt
    const [responseStatus, setResponseStatus] = useState(null); // State to track the response status


    const handleFileChange = (event, setFieldValue) => {
        const files = event.currentTarget.files;
        let errors = {};

        if (files) {
            Array.from(files).forEach((file) => {
                if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                    errors[file.name] = 'File size exceeds 5 MB';
                }
            });

            if (Object.keys(errors).length === 0) {
                setFieldValue('images', files);
                setFileErrors({});
            } else {
                setFileErrors(errors);
                setFieldValue('images', null); // Reset the field value if there are errors
            }
        }
    };

    const handleSubmit = async (values, {setSubmitting, resetForm}) => {


        try {
            const formData = new FormData();
            formData.append('firstName', values.firstName);
            formData.append('lastName', values.lastName);
            formData.append('email', values.email);
            formData.append('reportType', values.reportType);
            formData.append('date', values.date);
            formData.append('location', values.location);
            formData.append('turtleType', values.turtleType);
            formData.append('species', values.species);
            formData.append('speciesText', values.speciesText);
            formData.append('size', values.size);
            formData.append('weight', values.weight);
            formData.append('gender', values.gender);
            formData.append('marks', values.marks);

            if (values.images) {
                Array.from(values.images).forEach((file, index) => {
                    formData.append(`images[${index}]`, file);
                });
            }

            const response = await fetch('./app/submit.php', {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();

            console.log(result);
            //alert('Meldung abgeschickt'); //${JSON.stringify(result, null, 2)}

            // Check the status in the JSON response
            if (result.status === 'success') {
                // If the response indicates success, set success message
                setResponseStatus({
                    type: 'success',
                    message: `Ihre Meldung wurde erfolgreich abgeschickt. Wir werden eine E-Mail mit einer Bestätigung an die von Ihnen angegebene E-Mail-Adresse senden. (Falls Sie keine E-Mail bekommen, überprüfen Sie Ihre Spam Ordner)`,
                    id: result.id
                });
            } else {
                // If the response indicates an error, set error message
                setResponseStatus({
                    type: 'error',
                    message: result.message || 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
                });
            }

            resetForm();
        } catch (error) {
            setResponseStatus({
                type: 'error',
                message: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
            });
            console.error('Form submission error:', error);
        }
        setSubmitting(false);
    };

    const goToNextStep = async (validateForm) => {
        const errors = await validateForm();
        if (Object.keys(errors).length === 0) {
            setCurrentStep(currentStep + 1);
        }
    };

    const goToPreviousStep = () => setCurrentStep(currentStep - 1);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(responseStatus.id);
        //alert('ID kopiert: ' + responseStatus.id); // Optional alert to notify the user that the ID has been copied
    };

    if (responseStatus) {
        return (
            <div className="response-message">
                {responseStatus.type === 'success' ? (
                    <div className="message-container success">
                        <h2 className={"bigger-heading"}>Erfolg!</h2>
                        <p>{responseStatus.message}</p>
                        <br/>
                        <p>Ihre Meldungs-ID lautet <strong>{responseStatus.id}</strong>. Bitte geben Sie diese ID bei
                            Rückfragen oder Kontaktaufnahme an.</p>
                        <br/>
                        <p className={"result-id"}>Individuelle ID: <span> {responseStatus.id}</span>
                            <button onClick={copyToClipboard} className="copy-button">
                                <img src={copyImg} alt="Copy ID" className="copy-icon"/>
                            </button>
                        </p>
                    </div>
                ) : (
                    <div className="message-container error">
                        <h2 className={"bigger-heading"}>Fehler</h2>
                        <p>{responseStatus.message}</p>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="contact-container">
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    reportType: 'found',
                    date: new Date(),
                    location: '',
                    turtleType: 'land',
                    species: 'unknown',
                    speciesText: '',
                    size: '',
                    weight: '',
                    gender: 'unknown',
                    marks: '',
                    images: null,
                }}
                validationSchema={validationSchemas[currentStep - 1]}
                onSubmit={handleSubmit}
                validateOnBlur={false}
            >
                {({values, setFieldValue, isSubmitting, validateForm}) => (
                    <Form id={"turtleForm"}>
                        <h2 className={"bigger-heading"}>Meldeformular</h2>
                        <div className={"spacer-50"}></div>
                        <ProgressBar step={currentStep}/>

                        {currentStep === 1 && (
                            <>
                                <h2>Kontakt</h2>
                                <div>
                                    <label htmlFor="firstName">Vorname*</label>
                                    <Field id="firstName" name="firstName" type="text"/>
                                    <ErrorMessage name="firstName" component="div" className="error"/>
                                </div>
                                <div>
                                    <label htmlFor="lastName">Nachname*</label>
                                    <Field id="lastName" name="lastName" type="text"/>
                                    <ErrorMessage name="lastName" component="div" className="error"/>
                                </div>
                                <div>
                                    <label htmlFor="email">Email*</label>
                                    <Field id="email" name="email" type="email"/>
                                    <ErrorMessage name="email" component="div" className="error"/>
                                </div>
                            </>
                        )}

                        {currentStep === 2 && (
                            <>
                                <h2>Meldeinformationen</h2>
                                <div className="toggle-group">
                                    <Field
                                        type="radio"
                                        id="reportTypeFound"
                                        name="reportType"
                                        value="found"
                                        checked={values.reportType === 'found'}
                                    />
                                    <label htmlFor="reportTypeFound">Fundmeldung</label>
                                    <Field
                                        type="radio"
                                        id="reportTypeLost"
                                        name="reportType"
                                        value="lost"
                                        checked={values.reportType === 'lost'}
                                    />
                                    <label htmlFor="reportTypeLost">Suchmeldung</label>
                                </div>
                                <div>
                                    <label htmlFor="date">Fund/Verlustdatum*</label>
                                    <DatePicker
                                        id="date"
                                        selected={(values.date && new Date(values.date)) || null}
                                        onChange={(val) => setFieldValue('date', val)}
                                    />
                                    <ErrorMessage name="date" component="div" className="error"/>
                                </div>
                                <div>
                                    <label htmlFor="location">Fund/Verlustort (Adresse und Postleitzahl)*</label>
                                    <Field id="location" name="location" type="text"/>
                                    <ErrorMessage name="location" component="div" className="error"/>
                                </div>
                            </>
                        )}

                        {currentStep === 3 && (
                            <>
                                <h2>Schildkröteninformationen</h2>
                                <div className="toggle-group">
                                    <Field
                                        type="radio"
                                        id="turtleTypeLand"
                                        name="turtleType"
                                        value="land"
                                        checked={values.turtleType === 'land'}
                                    />
                                    <label htmlFor="turtleTypeLand">Landschildkröte</label>
                                    <Field
                                        type="radio"
                                        id="turtleTypeWater"
                                        name="turtleType"
                                        value="water"
                                        checked={values.turtleType === 'water'}
                                    />
                                    <label htmlFor="turtleTypeWater">Wasserschildkröte</label>
                                </div>
                                <div>
                                    <label htmlFor="species">Schildkrötenart</label>
                                    <Field
                                        id="species"
                                        name="species"
                                        as="select"
                                        onChange={(e) => setFieldValue('species', e.target.value)}
                                    >
                                        <option value="unknown">Unbekannt</option>
                                        <option value="other">Bekannt</option>
                                    </Field>
                                    {values.species === 'other' && (
                                        <div>
                                            <Field
                                                id="speciesText"
                                                name="speciesText"
                                                type="text"
                                                placeholder="Spezifizieren Sie die Art"
                                            />
                                            <ErrorMessage name="speciesText"/>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <label htmlFor="size">Ungefähre Größe (cm)*</label>
                                    <Field id="size" name="size" type="number"/>
                                    <ErrorMessage name="size" component="div" className="error"/>
                                </div>
                                <div>
                                    <label htmlFor="weight">Ungefähres Gewicht (g)*</label>
                                    <Field id="weight" name="weight" type="number"/>
                                    <ErrorMessage name="weight" component="div" className="error"/>
                                </div>
                                <div>
                                    <label htmlFor="gender">Geschlecht</label>
                                    <Field id="gender" name="gender" as="select">
                                        <option value="male">Männlich</option>
                                        <option value="female">Weiblich</option>
                                        <option value="unknown">Unbekannt</option>
                                    </Field>
                                </div>
                                <div>
                                    <label htmlFor="marks">Besondere Merkmale</label>
                                    <Field id="marks" name="marks" as="textarea"/>
                                </div>
                                <div>
                                    <label htmlFor="images">Bilder (max. 5 MB pro Bild, nur .jpg und .png)</label>
                                    <div>Wenn möglich, Bilder von Rücken- und Bauchpanzer beifügen. Bei
                                        Wasserschildkröten wenn möglich zusätzlich ein seitliches Foto des Kopfes. <p/>
                                    </div>
                                    <input
                                        id="images"
                                        name="images"
                                        type="file"
                                        onChange={(event) => handleFileChange(event, setFieldValue)}
                                        multiple
                                    />
                                    {Object.keys(fileErrors).map((fileName) => (
                                        <div key={fileName} className="error">
                                            {fileErrors[fileName]}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        <div className="spacer-50"></div>
                        {currentStep > 1 && (
                            <button type="button" onClick={goToPreviousStep}>
                                Zurück
                            </button>
                        )}
                        {currentStep < 3 ? (
                            <button
                                type="button"
                                onClick={() => goToNextStep(validateForm)}
                                disabled={isSubmitting}
                            >
                                Weiter
                            </button>
                        ) : (
                            <div>
                                <label className={"terms-conditions"}>
                                    <Field type="checkbox" name="agree"/>
                                    <span>Ich stimme der Datenschutzerklärung dieser Seite, sowie der Verarbeitung, Verbreitung, und Veröffentlichung meiner Daten auf Facebook zu.*</span>
                                </label>
                                <ErrorMessage name="agree" component="div" className="error"/>
                                <button type="submit" disabled={isSubmitting || !values.agree}>
                                    Absenden
                                </button>
                                <div>(Dies kann einen Moment dauern, bitte laden Sie die Seite nicht neu)</div>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    )
        ;
};

const StatusCheck = () => {
    const [id, setId] = useState('');
    const [caseStatus, setCaseStatus] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const checkStatus = async () => {
        setLoading(true);
        try {
            const response = await fetch(`./app/status.php?id=${id}`);
            const result = await response.json();

            if (response.ok) {
                if (result.status === 'error') {
                    setError(result.message); // Display the error message from the response
                    setCaseStatus(null);
                } else {
                    setCaseStatus(result.case_status); // Ensure this matches the actual JSON key
                    setError(null);
                }
            }else {
                setCaseStatus(null);
                setError(result.message || 'Fehler beim Abrufen des Status');
            }
        } catch (err) {
            setCaseStatus(null);
            setError('Fehler beim Abrufen des Status');
        } finally {
            setLoading(false);
        }
    };

    const renderStatusMessage = () => {
        switch (caseStatus) {
            case 'NEW':
                return <p><strong> Status: </strong>Ihre Meldung ist neu und wird bearbeitet.</p>;
            case 'ACCEPTED':
                return <p><strong> Status: </strong>Ihre Meldung wurde akzeptiert.</p>;
            case 'REJECTED':
                return <p><strong> Status: </strong>Leider wurde Ihre Meldung abgelehnt.</p>;
            case 'FOUND':
                return <p><strong> Status: </strong>Das gesuchte Tier wurde erfolgreich gefunden!</p>;
            case 'RETURNED':
                return <p><strong> Status: </strong>Das gefundene Tier wurde erfolgreich nach Hause gebracht!</p>;
            case 'DEAD':
                return <p><strong> Status: </strong>Das Tier ist leider verstorben.</p>;
            default:
                return <p>Diese ID ist nicht verfügbar.</p>;
        }
    };

    return (
        <div>
            <input className="status-input"
                   type="text"
                   value={id}
                   onChange={(e) => setId(e.target.value)}
                   placeholder="Geben Sie Ihre ID ein"
            />
            <div className="spacer-30"/>
            <button onClick={checkStatus} disabled={loading}>
                {loading ? 'Laden...' : 'Status prüfen'}
            </button>

            {caseStatus && renderStatusMessage()}
            {error && <p className="error">{error}</p>}
        </div>
    );
};


const Kontakt = () => {
    return (
        <div>
            <div className="content-padding ">
                <h1 className="help-h1 ">Such- oder <br/>Fundmeldung abgeben</h1>
                <p>
                    Auf dieser Seite können Sie unkompliziert eine Fund- oder Suchmeldung für Schildkröten über unser
                    Kontaktformular einreichen.
                    Ihre Meldung wird in unserer Facebook-Gruppe sowie auf der Google Maps-Karte veröffentlicht, um die
                    Reichweite zu erhöhen und die Chancen auf eine erfolgreiche Rückkehr zu verbessern. Falls Sie bereits eine Meldung eingereicht haben, können Sie auf dieser Seite den Status überprüfen.
                </p>
                <p> Wenn Sie Fragen haben oder Unterstützung benötigen, steht Ihnen unser Team jederzeit per E-Mail oder
                    über Facebook zur Verfügung.
                </p>
                <Button link="https://bit.ly/Schildisuche " text="zur Facebook Gruppe"/>
                <Button link="#turtleForm" text="→ zum Meldeformular"/>
                <br/>
                <h2 className={"bigger-heading"}>Status überprüfen</h2>
                <p> Wenn Sie bereits eine Meldung über unser Melde-Formular abgegeben haben, können Sie hier den Status
                    einsehen. Geben Sie dazu einfach Ihre individuelle Melde-ID an, die Sie per E-Mail bekommen haben.
                    Sollten Sie dazu Rückfragen haben, können Sie uns über Facebook oder die oben angegebene E-Mail Adresse
                    erreichen.
                </p>
                <StatusCheck/>
            </div>

            <section className="divider-section"></section>

            <div className="content-padding contact-form">

                <ContactForm/>
            </div>

            <section className="divider-section"></section>

            {/*<div className={"content-padding"}>
                <div className="response-message">
                        <div className="message-container error">
                            <h2 className={"bigger-heading"}>Erfolg</h2>
                            <p>lorem ipsum</p>
                            <p>Ihre Meldungs-ID lautet: <span className={"result-id"}>12346</span></p>
                        </div>
                </div>
            </div>*/}
        </div>
    );
}

export default Kontakt;
