// Button.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css';

const Button = ({ link, text }) => {
    // Check if the link is an external URL, mailto link, or an anchor link
    const isExternal = link.startsWith('http://') || link.startsWith('https://');
    const isMailTo = link.startsWith('mailto:');
    const isAnchor = link.startsWith('#');

    if (isExternal) {
        return (
            <a href={link} className="button" target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        );
    } else if (isMailTo) {
        return (
            <a href={link} className="button">
                {text}
            </a>
        );
    } else if (isAnchor) {
        return (
            <a href={link} className="button">
                {text}
            </a>
        );
    } else {
        return (
            <Link to={link} className="button">
                {text}
            </Link>
        );
    }
}

export default Button;
