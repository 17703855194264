// Datenschutz.js
import React from "react";

const Datenschutz = () => {
    return (
        <div className="content-padding datenschutz">
            <h1>Datenschutzerklärung</h1>
            <h2 id="m716">Präambel</h2>
            <p>Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer
                personenbezogenen Daten (nachfolgend auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in
                welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen
                personenbezogener Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf
                unseren Webseiten, in mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z. B. unserer
                Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als "Onlineangebot").</p>
            <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>

            <p>Stand: 10. August 2024</p><h2>Inhaltsübersicht</h2>
            <ul className="index">
                <li><a className="index-link" href="#m716">Präambel</a></li>
                <li><a className="index-link" href="#m3">Verantwortlicher</a></li>
                <li><a className="index-link" href="#m11">Kontakt Datenschutzbeauftragter</a></li>
                <li><a className="index-link" href="#mOverview">Übersicht der Verarbeitungen</a></li>
                <li><a className="index-link" href="#m2427">Maßgebliche Rechtsgrundlagen</a></li>
                <li><a className="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
                <li><a className="index-link" href="#m25">Übermittlung von personenbezogenen Daten</a></li>
                <li><a className="index-link" href="#m24">Internationale Datentransfers</a></li>
                <li><a className="index-link" href="#m12">Allgemeine Informationen zur Datenspeicherung und Löschung</a>
                </li>
                <li><a className="index-link" href="#m10">Rechte der betroffenen Personen</a></li>
                <li><a className="index-link" href="#m225">Bereitstellung des Onlineangebots und Webhosting</a></li>
                <li><a className="index-link" href="#m182">Kontakt- und Anfrageverwaltung</a></li>
                <li><a className="index-link" href="#m17">Newsletter und elektronische Benachrichtigungen</a></li>
                <li><a className="index-link" href="#m136">Präsenzen in sozialen Netzwerken (Social Media)</a></li>
                <li><a className="index-link" href="#m328">Plug-ins und eingebettete Funktionen sowie Inhalte</a></li>
            </ul>
            <h2 id="m3">Verantwortlicher</h2><p>Eric Hustinx,<br/>Dieselstraße 4<br/>38122 Braunschweig,<br/>Deutschland
        </p>
            <p>E-Mail-Adresse: <a
                href="mailto:datenschutz@schildkroetensuche.org">datenschutz@schildkroetensuche.org</a></p>
            <p>Impressum: www.schildkroetensuche.org/impressum</p>

            <h2 id="m11">Kontakt Datenschutzbeauftragter</h2><p><a
            href="mailto:datenschutz@schildkroetensuche.org">datenschutz@schildkroetensuche.org</a></p>

            <h2 id="mOverview">Übersicht der Verarbeitungen</h2><p>Die nachfolgende Übersicht fasst die Arten der
            verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
            Personen.</p><h3>Arten der verarbeiteten Daten</h3>
            <ul>
                <li>Bestandsdaten.</li>
                <li>Standortdaten.</li>
                <li>Kontaktdaten.</li>
                <li>Inhaltsdaten.</li>
                <li>Nutzungsdaten.</li>
                <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
                <li>Protokolldaten.</li>
            </ul>
            <h3>Kategorien betroffener Personen</h3>
            <ul>
                <li>Kommunikationspartner.</li>
                <li>Nutzer.</li>
            </ul>
            <h3>Zwecke der Verarbeitung</h3>
            <ul>
                <li>Kommunikation.</li>
                <li>Direktmarketing.</li>
                <li>Organisations- und Verwaltungsverfahren.</li>
                <li>Feedback.</li>
                <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
                <li>Informationstechnische Infrastruktur.</li>
                <li>Öffentlichkeitsarbeit.</li>
            </ul>
            <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2><p><strong>Maßgebliche Rechtsgrundlagen nach der
            DSGVO: </strong>Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir
            personenbezogene Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO
            nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im
            Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung
            mit.</p>
            <ul>
                <li><strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die betroffene Person hat ihre
                    Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen spezifischen
                    Zweck oder mehrere bestimmte Zwecke gegeben.
                </li>
                <li><strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO)</strong> -
                    Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person
                    ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen
                    Person erfolgen.
                </li>
                <li><strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong> - die Verarbeitung ist
                    zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten notwendig,
                    vorausgesetzt, dass die Interessen, Grundrechte und Grundfreiheiten der betroffenen Person, die den
                    Schutz personenbezogener Daten verlangen, nicht überwiegen.
                </li>
            </ul>
            <p><strong>Nationale Datenschutzregelungen in Deutschland: </strong>Zusätzlich zu den Datenschutzregelungen
                der DSGVO gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere das
                Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung
                (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf
                Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
                personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten
                Entscheidungsfindung im Einzelfall einschließlich Profiling. Ferner können Landesdatenschutzgesetze der
                einzelnen Bundesländer zur Anwendung gelangen.</p>
            <p><strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese Datenschutzhinweise dienen sowohl der
                Informationserteilung nach dem Schweizer DSG als auch nach der Datenschutzgrundverordnung (DSGVO). Aus
                diesem Grund bitten wir Sie zu beachten, dass aufgrund der breiteren räumlichen Anwendung und
                Verständlichkeit die Begriffe der DSGVO verwendet werden. Insbesondere statt der im Schweizer DSG
                verwendeten Begriffe „Bearbeitung" von „Personendaten", "überwiegendes Interesse" und "besonders
                schützenswerte Personendaten" werden die in der DSGVO verwendeten Begriffe „Verarbeitung" von
                „personenbezogenen Daten" sowie "berechtigtes Interesse" und "besondere Kategorien von Daten" verwendet.
                Die gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der Geltung des Schweizer DSG weiterhin
                nach dem Schweizer DSG bestimmt.</p>

            <h2 id="m27">Sicherheitsmaßnahmen</h2><p>Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
            Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände
            und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes
            der Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und organisatorische
            Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.</p>
            <p>Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von
                Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie
                betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung.
                Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung
                von Daten und Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
                Schutz personenbezogener Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie
                Verfahren entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und durch
                datenschutzfreundliche Voreinstellungen.</p>

            <h2 id="m25">Übermittlung von personenbezogenen Daten</h2><p>Im Rahmen unserer Verarbeitung von
            personenbezogenen Daten kommt es vor, dass diese an andere Stellen, Unternehmen, rechtlich selbstständige
            Organisationseinheiten oder Personen übermittelt beziehungsweise ihnen gegenüber offengelegt werden. Zu den
            Empfängern dieser Daten können z. B. mit IT-Aufgaben beauftragte Dienstleister gehören oder Anbieter von
            Diensten und Inhalten, die in eine Website eingebunden sind. In solchen Fällen beachten wir die gesetzlichen
            Vorgaben und schließen insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten
            dienen, mit den Empfängern Ihrer Daten ab.</p>

            <h2 id="m24">Internationale Datentransfers</h2><p>Datenverarbeitung in Drittländern: Sofern wir Daten in
            einem Drittland (d. h., außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR))
            verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder der Offenlegung
            bzw. Übermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet, erfolgt dies nur im
            Einklang mit den gesetzlichen Vorgaben. Sofern das Datenschutzniveau in dem Drittland mittels eines
            Angemessenheitsbeschlusses anerkannt wurde (Art. 45 DSGVO), dient dieser als Grundlage des Datentransfers.
            Im Übrigen erfolgen Datentransfers nur dann, wenn das Datenschutzniveau anderweitig gesichert ist,
            insbesondere durch Standardvertragsklauseln (Art. 46 Abs. 2 lit. c) DSGVO), ausdrückliche Einwilligung oder
            im Fall vertraglicher oder gesetzlich erforderlicher Übermittlung (Art. 49 Abs. 1 DSGVO). Im Übrigen teilen
            wir Ihnen die Grundlagen der Drittlandübermittlung bei den einzelnen Anbietern aus dem Drittland mit, wobei
            die Angemessenheitsbeschlüsse als Grundlagen vorrangig gelten. Informationen zu Drittlandtransfers und
            vorliegenden Angemessenheitsbeschlüssen können dem Informationsangebot der EU-Kommission entnommen
            werden: <a
                href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
                target="_blank">https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.</a>
        </p>
            <p>EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten „Data Privacy Framework" (DPF) hat
                die EU-Kommission das Datenschutzniveau ebenfalls für bestimmte Unternehmen aus den USA im Rahmen der
                Angemessenheitsbeschlusses vom 10.07.2023 als sicher anerkannt. Die Liste der zertifizierten Unternehmen
                als auch weitere Informationen zu dem DPF können Sie der Website des Handelsministeriums der USA
                unter <a href="https://www.dataprivacyframework.gov/"
                         target="_blank">https://www.dataprivacyframework.gov/</a> (in Englisch) entnehmen. Wir
                informieren Sie im Rahmen der Datenschutzhinweise, welche von uns eingesetzten Diensteanbieter unter dem
                Data Privacy Framework zertifiziert sind.</p>

            <h2 id="m12">Allgemeine Informationen zur Datenspeicherung und Löschung</h2><p>Wir löschen personenbezogene
            Daten, die wir verarbeiten, gemäß den gesetzlichen Bestimmungen, sobald die zugrundeliegenden Einwilligungen
            widerrufen werden oder keine weiteren rechtlichen Grundlagen für die Verarbeitung bestehen. Dies betrifft
            Fälle, in denen der ursprüngliche Verarbeitungszweck entfällt oder die Daten nicht mehr benötigt werden.
            Ausnahmen von dieser Regelung bestehen, wenn gesetzliche Pflichten oder besondere Interessen eine längere
            Aufbewahrung oder Archivierung der Daten erfordern.</p>
            <p>Insbesondere müssen Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen oder
                deren Speicherung notwendig ist zur Rechtsverfolgung oder zum Schutz der Rechte anderer natürlicher oder
                juristischer Personen, entsprechend archiviert werden.</p>
            <p>Unsere Datenschutzhinweise enthalten zusätzliche Informationen zur Aufbewahrung und Löschung von Daten,
                die speziell für bestimmte Verarbeitungsprozesse gelten.</p>
            <p>Bei mehreren Angaben zur Aufbewahrungsdauer oder Löschungsfristen eines Datums, ist stets die längste
                Frist maßgeblich.</p>
            <p>Beginnt eine Frist nicht ausdrücklich zu einem bestimmten Datum und beträgt sie mindestens ein Jahr, so
                startet sie automatisch am Ende des Kalenderjahres, in dem das fristauslösende Ereignis eingetreten ist.
                Im Fall laufender Vertragsverhältnisse, in deren Rahmen Daten gespeichert werden, ist das
                fristauslösende Ereignis der Zeitpunkt des Wirksamwerdens der Kündigung oder sonstige Beendigung des
                Rechtsverhältnisses.</p>
            <p>Daten, die nicht mehr für den ursprünglich vorgesehenen Zweck, sondern aufgrund gesetzlicher Vorgaben
                oder anderer Gründe aufbewahrt werden, verarbeiten wir ausschließlich zu den Gründen, die ihre
                Aufbewahrung rechtfertigen.</p>
            <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
            <ul className="m-elements">
                <li><strong>Aufbewahrung und Löschung von Daten: </strong>Die folgenden allgemeinen Fristen gelten für
                    die Aufbewahrung und Archivierung nach deutschem Recht: <ul>
                        <li>10 Jahre - Aufbewahrungsfrist für Bücher und Aufzeichnungen, Jahresabschlüsse, Inventare,
                            Lageberichte, Eröffnungsbilanz sowie die zu ihrem Verständnis erforderlichen
                            Arbeitsanweisungen und sonstigen Organisationsunterlagen, Buchungsbelege und Rechnungen (§
                            147 Abs. 3 i. V. m. Abs. 1 Nr. 1, 4 und 4a AO, § 14b Abs. 1 UStG, § 257 Abs. 1 Nr. 1 u. 4,
                            Abs. 4 HGB).
                        </li>
                        <li>6 Jahre - Übrige Geschäftsunterlagen: empfangene Handels- oder Geschäftsbriefe, Wiedergaben
                            der abgesandten Handels- oder Geschäftsbriefe, sonstige Unterlagen, soweit sie für die
                            Besteuerung von Bedeutung sind, z. B. Stundenlohnzettel, Betriebsabrechnungsbögen,
                            Kalkulationsunterlagen, Preisauszeichnungen, aber auch Lohnabrechnungsunterlagen, soweit sie
                            nicht bereits Buchungsbelege sind und Kassenstreifen (§ 147 Abs. 3 i. V. m. Abs. 1 Nr. 2, 3,
                            5 AO, § 257 Abs. 1 Nr. 2 u. 3, Abs. 4 HGB).
                        </li>
                        <li>3 Jahre - Daten, die erforderlich sind, um potenzielle Gewährleistungs- und
                            Schadensersatzansprüche oder ähnliche vertragliche Ansprüche und Rechte zu berücksichtigen
                            sowie damit verbundene Anfragen zu bearbeiten, basierend auf früheren Geschäftserfahrungen
                            und üblichen Branchenpraktiken, werden für die Dauer der regulären gesetzlichen
                            Verjährungsfrist von drei Jahren gespeichert (§§ 195, 199 BGB).
                        </li>
                    </ul></li>
            </ul>
            <h2 id="m10">Rechte der betroffenen Personen</h2><p>Rechte der betroffenen Personen aus der DSGVO: Ihnen
            stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21 DSGVO
            ergeben:</p>
            <ul>
                <li><strong>Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation
                    ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund
                    von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf
                    diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten
                    verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
                    Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
                    dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</strong>
                </li>
                <li><strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen
                    jederzeit zu widerrufen.
                </li>
                <li><strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
                    betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere
                    Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
                </li>
                <li><strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das Recht,
                    die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden
                    unrichtigen Daten zu verlangen.
                </li>
                <li><strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Maßgabe der
                    gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht
                    werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung
                    der Daten zu verlangen.
                </li>
                <li><strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die Sie
                    uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen
                    und maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen
                    zu fordern.
                </li>
                <li><strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben unbeschadet eines anderweitigen
                    verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
                    Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthaltsorts, ihres
                    Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
                    Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die Vorgaben der DSGVO verstößt.
                </li>
            </ul>

            <h2 id="m225">Bereitstellung des Onlineangebots und Webhosting</h2><p>Wir verarbeiten die Daten der Nutzer,
            um ihnen unsere Online-Dienste zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
            IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen unserer Online-Dienste an den
            Browser oder das Endgerät der Nutzer zu übermitteln.</p>
            <ul className="m-elements">
                <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer,
                    Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und Betriebssysteme,
                    Interaktionen mit Inhalten und Funktionen); Meta-, Kommunikations- und Verfahrensdaten (z. B.
                    IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte Personen); Protokolldaten (z. B.
                    Logfiles betreffend Logins oder den Abruf von Daten oder Zugriffszeiten.). Inhaltsdaten (z. B.
                    textliche oder bildliche Nachrichten und Beiträge sowie die sie betreffenden Informationen, wie z.
                    B. Angaben zur Autorenschaft oder Zeitpunkt der Erstellung).
                </li>
                <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
                    Nutzerfreundlichkeit. Informationstechnische Infrastruktur (Betrieb und Bereitstellung von
                    Informationssystemen und technischen Geräten (Computer, Server etc.).).
                </li>
                <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt "Allgemeine
                    Informationen zur Datenspeicherung und Löschung".
                </li>
                <li className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
                    DSGVO).
                </li>
            </ul>
            <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
            <ul className="m-elements">
                <li><strong>Bereitstellung Onlineangebot auf gemietetem Speicherplatz: </strong>Für die Bereitstellung
                    unseres Onlineangebotes nutzen wir Speicherplatz, Rechenkapazität und Software, die wir von einem
                    entsprechenden Serveranbieter (auch "Webhoster" genannt) mieten oder anderweitig beziehen; <span
                        className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</span>
                </li>
                <li><strong>E-Mail-Versand und -Hosting: </strong>Die von uns in Anspruch genommenen
                    Webhosting-Leistungen umfassen ebenfalls den Versand, den Empfang sowie die Speicherung von E-Mails.
                    Zu diesen Zwecken werden die Adressen der Empfänger sowie Absender als auch weitere Informationen
                    betreffend den E-Mailversand (z. B. die beteiligten Provider) sowie die Inhalte der jeweiligen
                    E-Mails verarbeitet. Die vorgenannten Daten können ferner zu Zwecken der Erkennung von SPAM
                    verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im Internet grundsätzlich nicht
                    verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar auf dem Transportweg verschlüsselt,
                    aber (sofern kein sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den
                    Servern, von denen sie abgesendet und empfangen werden. Wir können daher für den Übertragungsweg der
                    E-Mails zwischen dem Absender und dem Empfang auf unserem Server keine Verantwortung
                    übernehmen; <span className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</span>
                </li>
            </ul>
            <h2 id="m182">Kontakt- und Anfrageverwaltung</h2><p>Bei der Kontaktaufnahme mit uns (z. B. per Post,
            Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender Nutzer- und
            Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet, soweit dies zur Beantwortung
            der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.</p>
            <ul className="m-elements">
                <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der vollständige Name, Wohnadresse,
                    Kontaktinformationen, Kundennummer, etc.); Kontaktdaten (z. B. Post- und E-Mail-Adressen oder
                    Telefonnummern); Inhaltsdaten (z. B. textliche oder bildliche Nachrichten und Beiträge sowie die sie
                    betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder Zeitpunkt der Erstellung);
                    Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und -frequenz,
                    verwendete Gerätetypen und Betriebssysteme, Interaktionen mit Inhalten und Funktionen). Meta-,
                    Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern,
                    beteiligte Personen).
                </li>
                <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
                <li><strong>Zwecke der Verarbeitung:</strong> Kommunikation; Organisations- und Verwaltungsverfahren;
                    Feedback (z. B. Sammeln von Feedback via Online-Formular). Bereitstellung unseres Onlineangebotes
                    und Nutzerfreundlichkeit.
                </li>
                <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt "Allgemeine
                    Informationen zur Datenspeicherung und Löschung".
                </li>
                <li className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
                    DSGVO). Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                </li>
            </ul>
            <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
            <ul className="m-elements">
                <li><strong>Kontaktformular: </strong>Bei Kontaktaufnahme über unser Kontaktformular, per E-Mail oder
                    anderen Kommunikationswegen, verarbeiten wir die uns übermittelten personenbezogenen Daten zur
                    Beantwortung und Bearbeitung des jeweiligen Anliegens. Dies umfasst in der Regel Angaben wie Name,
                    Kontaktinformationen und gegebenenfalls weitere Informationen, die uns mitgeteilt werden und zur
                    angemessenen Bearbeitung erforderlich sind. Wir nutzen diese Daten ausschließlich für den
                    angegebenen Zweck der Kontaktaufnahme und Kommunikation; <span
                        className=""><strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</span>
                </li>
            </ul>
            <h2 id="m17">Newsletter und elektronische Benachrichtigungen</h2><p>Wir versenden Newsletter, E-Mails und
            weitere elektronische Benachrichtigungen (nachfolgend „Newsletter") ausschließlich mit der Einwilligung der
            Empfänger oder aufgrund einer gesetzlichen Grundlage. Sofern im Rahmen einer Anmeldung zum Newsletter dessen
            Inhalte genannt werden, sind diese Inhalte für die Einwilligung der Nutzer maßgeblich. Für die Anmeldung zu
            unserem Newsletter ist normalerweise die Angabe Ihrer E-Mail-Adresse ausreichend. Um Ihnen jedoch einen
            personalisierten Service bieten zu können, bitten wir gegebenenfalls um die Angabe Ihres Namens für eine
            persönliche Ansprache im Newsletter oder um weitere Informationen, falls diese für den Zweck des Newsletters
            notwendig sind.</p>
            <p>Löschung und Einschränkung der Verarbeitung: Wir können die ausgetragenen E-Mail-Adressen bis zu drei
                Jahren auf Grundlage unserer berechtigten Interessen speichern, bevor wir sie löschen, um eine ehemals
                gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird auf den Zweck einer
                potenziellen Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich,
                sofern zugleich das ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten zur
                dauerhaften Beachtung von Widersprüchen behalten wir uns die Speicherung der E-Mail-Adresse alleine zu
                diesem Zweck in einer Sperrliste (sogenannte „Blocklist") vor.</p>
            <p>Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen zum Zweck
                des Nachweises seines ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem Versand von
                E-Mails beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen an einem effizienten und
                sicheren Versandsystem.</p>
            <strong>Inhalte:</strong> <p>Informationen über den Status der Kontaktaufnahme bzw. Fund- oder
            Suchmeldung.</p>
            <ul className="m-elements">
                <li><strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der vollständige Name, Wohnadresse,
                    Kontaktinformationen, Kundennummer, etc.); Kontaktdaten (z. B. Post- und E-Mail-Adressen oder
                    Telefonnummern); Meta-, Kommunikations- und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
                    Identifikationsnummern, beteiligte Personen). Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer,
                    Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und Betriebssysteme,
                    Interaktionen mit Inhalten und Funktionen).
                </li>
                <li><strong>Betroffene Personen:</strong> Kommunikationspartner.</li>
                <li><strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z. B. per E-Mail oder postalisch).</li>
                <li><strong>Aufbewahrung und Löschung:</strong> 3 Jahre - Vertragliche Ansprüche (AT) (Daten, die
                    erforderlich sind, um potenzielle Gewährleistungs- und Schadensersatzansprüche oder ähnliche
                    vertragliche Ansprüche und Rechte zu berücksichtigen sowie damit verbundene Anfragen zu bearbeiten,
                    basierend auf früheren Geschäftserfahrungen und üblichen Branchenpraktiken, werden für die Dauer der
                    regulären gesetzlichen Verjährungsfrist von drei Jahren gespeichert (§§ 1478, 1480 ABGB).). 10 Jahre
                    - Vertragliche Ansprüche (CH) (Daten, die zur Berücksichtigung potenzieller Schadenersatzansprüche
                    oder ähnlicher vertraglicher Ansprüche und Rechte notwendig sind, sowie für die Bearbeitung damit
                    verbundener Anfragen, basierend auf früheren Geschäftserfahrungen und den üblichen
                    Branchenpraktiken, werden für den Zeitraum der gesetzlichen Verjährungsfrist von zehn Jahren
                    gespeichert, es sei denn, eine kürzere Frist von 5 Jahren ist maßgeblich, die in bestimmten Fällen
                    einschlägig ist (Art. 127, 130 OR)).
                </li>
                <li className=""><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
                </li>
                <li><strong>Widerspruchsmöglichkeit (Opt-Out): </strong>Sie können den Empfang unseres Newsletters
                    jederzeit kündigen, d. .h. Ihre Einwilligungen widerrufen, bzw. dem weiteren Empfang widersprechen.
                    Einen Link zur Kündigung des Newsletters finden Sie entweder am Ende eines jeden Newsletters oder
                    können sonst eine der oben angegebenen Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen.
                </li>
            </ul>
            <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
            <ul className="m-elements">
                <li><strong>Messung von Öffnungs- und Klickraten: </strong>Die Newsletter enthalten einen sogenannten
                    „Web Beacons", d. h. eine pixelgroße Datei, die beim Öffnen des Newsletters von unserem bzw. dessen
                    Server, sofern wir einen Versanddienstleister einsetzen, abgerufen wird. Im Rahmen dieses Abrufs
                    werden zunächst sowohl technische Informationen, wie beispielsweise Angaben zum Browser und Ihrem
                    System, als auch Ihre IP-Adresse und der Zeitpunkt des Abrufs erhoben. Diese Informationen werden
                    zur technischen Verbesserung unseres Newsletters anhand der technischen Daten oder der Zielgruppen
                    und ihres Leseverhaltens auf Basis ihrer Abruforte (die mithilfe der IP-Adresse bestimmbar sind)
                    oder der Zugriffszeiten genutzt. Diese Analyse beinhaltet ebenfalls die Feststellung, ob und wann
                    die Newsletter geöffnet und welche Links angeklickt werden. Die Informationen werden den einzelnen
                    Newsletterempfängern zugeordnet und in deren Profilen bis zur Löschung gespeichert. Die Auswertungen
                    dienen dazu, die Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte an sie anzupassen
                    oder unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu versenden. Die Messung
                    der Öffnungs- und Klickraten sowie die Speicherung der Messergebnisse in den Profilen der
                    Nutzer<span className="dsg-license-content-blurred de dsg-ttip-activate"> <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).</span>
                </li>
            </ul>
            <h2 id="m136">Präsenzen in sozialen Netzwerken (Social Media)</h2><p>Wir unterhalten Onlinepräsenzen
            innerhalb sozialer Netzwerke und verarbeiten in diesem Rahmen Nutzerdaten, um mit den dort aktiven Nutzern
            zu kommunizieren oder Informationen über uns anzubieten.</p>
            <p>Wir weisen darauf hin, dass dabei Nutzerdaten außerhalb des Raumes der Europäischen Union verarbeitet
                werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so zum Beispiel die
                Durchsetzung der Nutzerrechte erschwert werden könnte.</p>
            <p>Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für Marktforschungs- und
                Werbezwecke verarbeitet. So können beispielsweise anhand des Nutzungsverhaltens und sich daraus
                ergebender Interessen der Nutzer Nutzungsprofile erstellt werden. Letztere finden möglicherweise
                wiederum Verwendung, um etwa Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die
                mutmaßlich den Interessen der Nutzer entsprechen. Daher werden im Regelfall Cookies auf den Rechnern der
                Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden.
                Zudem können in den Nutzungsprofilen auch Daten unabhängig der von den Nutzern verwendeten Geräten
                gespeichert werden (insbesondere, wenn sie Mitglieder der jeweiligen Plattformen und dort eingeloggt
                sind).</p>
            <p>Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der Widerspruchsmöglichkeiten
                (Opt-out) verweisen wir auf die Datenschutzerklärungen und Angaben der Betreiber der jeweiligen
                Netzwerke.</p>
            <p>Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir darauf hin,
                dass diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur Letztere haben jeweils
                Zugriff auf die Nutzerdaten und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben.
                Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an uns wenden.</p>
            <ul className="m-elements">
                <li><strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z. B. Post- und E-Mail-Adressen oder
                    Telefonnummern); Inhaltsdaten (z. B. textliche oder bildliche Nachrichten und Beiträge sowie die sie
                    betreffenden Informationen, wie z. B. Angaben zur Autorenschaft oder Zeitpunkt der Erstellung).
                    Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und -frequenz,
                    verwendete Gerätetypen und Betriebssysteme, Interaktionen mit Inhalten und Funktionen).
                </li>
                <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li><strong>Zwecke der Verarbeitung:</strong> Kommunikation; Feedback (z. B. Sammeln von Feedback via
                    Online-Formular). Öffentlichkeitsarbeit.
                </li>
                <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt "Allgemeine
                    Informationen zur Datenspeicherung und Löschung".
                </li>
                <li className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
                    DSGVO).
                </li>
            </ul>
            <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
            <ul className="m-elements">
                <li><strong>Instagram: </strong>Soziales Netzwerk, ermöglicht das Teilen von Fotos und Videos, das
                    Kommentieren und Favorisieren von Beiträgen, Nachrichtenversand, Abonnieren von Profilen und
                    Seiten; <strong>Dienstanbieter:</strong> Meta Platforms Ireland Limited, Merrion Road, Dublin 4, D04
                    X2K5, Irland; <span className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); </span><strong>Website:</strong>
                    <a href="https://www.instagram.com"
                       target="_blank">https://www.instagram.com</a>; <strong>Datenschutzerklärung:</strong> <a
                        href="https://privacycenter.instagram.com/policy/"
                        target="_blank">https://privacycenter.instagram.com/policy/</a>. <strong>Grundlage
                        Drittlandtransfers:</strong> Data Privacy Framework (DPF).
                </li>
                <li><strong>Facebook-Seiten: </strong>Profile innerhalb des sozialen Netzwerks Facebook - Wir sind
                    gemeinsam mit Meta Platforms Ireland Limited für die Erhebung (jedoch nicht die weitere
                    Verarbeitung) von Daten der Besucher unserer Facebook-Seite (sog. "Fanpage") verantwortlich. Zu
                    diesen Daten gehören Informationen zu den Arten von Inhalten, die Nutzer sich ansehen oder mit denen
                    sie interagieren, oder die von ihnen vorgenommenen Handlungen (siehe unter „Von dir und anderen
                    getätigte und bereitgestellte Dinge" in der Facebook-Datenrichtlinie: <a
                        href="https://www.facebook.com/privacy/policy/"
                        target="_blank">https://www.facebook.com/privacy/policy/</a>), sowie Informationen über die von
                    den Nutzern genutzten Geräte (z. B. IP-Adressen, Betriebssystem, Browsertyp, Spracheinstellungen,
                    Cookie-Daten; siehe unter „Geräteinformationen" in der Facebook-Datenrichtlinie: <a
                        href="https://www.facebook.com/privacy/policy/"
                        target="_blank">https://www.facebook.com/privacy/policy/</a>). Wie in der
                    Facebook-Datenrichtlinie unter „Wie verwenden wir diese Informationen?" erläutert, erhebt und
                    verwendet Facebook Informationen auch, um Analysedienste, so genannte "Seiten-Insights", für
                    Seitenbetreiber bereitzustellen, damit diese Erkenntnisse darüber erhalten, wie Personen mit ihren
                    Seiten und mit den mit ihnen verbundenen Inhalten interagieren. Wir haben mit Facebook eine
                    spezielle Vereinbarung abgeschlossen ("Informationen zu Seiten-Insights", <a
                        href="https://www.facebook.com/legal/terms/page_controller_addendum"
                        target="_blank">https://www.facebook.com/legal/terms/page_controller_addendum</a>), in der
                    insbesondere geregelt wird, welche Sicherheitsmaßnahmen Facebook beachten muss und in der Facebook
                    sich bereit erklärt hat die Betroffenenrechte zu erfüllen (d. h. Nutzer können z. B. Auskünfte oder
                    Löschungsanfragen direkt an Facebook richten). Die Rechte der Nutzer (insbesondere auf Auskunft,
                    Löschung, Widerspruch und Beschwerde bei zuständiger Aufsichtsbehörde), werden durch die
                    Vereinbarungen mit Facebook nicht eingeschränkt. Weitere Hinweise finden sich in den "Informationen
                    zu Seiten-Insights" (<a
                        href="https://www.facebook.com/legal/terms/information_about_page_insights_data"
                        target="_blank">https://www.facebook.com/legal/terms/information_about_page_insights_data</a>).
                    Die gemeinsame Verantwortlichkeit beschränkt sich auf die Erhebung durch und Übermittlung von Daten
                    an Meta Platforms Ireland Limited, ein Unternehmen mit Sitz in der EU. Die weitere Verarbeitung der
                    Daten liegt in der alleinigen Verantwortung von Meta Platforms Ireland Limited, was insbesondere die
                    Übermittlung der Daten an die Muttergesellschaft Meta Platforms, Inc. in den USA
                    betrifft; <strong>Dienstanbieter:</strong> Meta Platforms Ireland Limited, Merrion Road, Dublin 4,
                    D04 X2K5, Irland; <span className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); </span><strong>Website:</strong>
                    <a href="https://www.facebook.com"
                       target="_blank">https://www.facebook.com</a>; <strong>Datenschutzerklärung:</strong> <a
                        href="https://www.facebook.com/privacy/policy/"
                        target="_blank">https://www.facebook.com/privacy/policy/</a>. <strong>Grundlage
                        Drittlandtransfers:</strong> Data Privacy Framework (DPF).
                </li>
            </ul>
            <h2 id="m328">Plug-ins und eingebettete Funktionen sowie Inhalte</h2><p>Wir binden Funktions- und
            Inhaltselemente in unser Onlineangebot ein, die von den Servern ihrer jeweiligen Anbieter (nachfolgend als
            „Drittanbieter" bezeichnet) bezogen werden. Dabei kann es sich zum Beispiel um Grafiken, Videos oder
            Stadtpläne handeln (nachfolgend einheitlich als „Inhalte" bezeichnet).</p>
            <p>Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der Nutzer
                verarbeiten, da sie ohne IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse
                ist damit für die Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen uns, nur solche
                Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte
                anzuwenden. Drittanbieter können ferner sogenannte Pixel-Tags (unsichtbare Grafiken, auch als „Web
                Beacons" bezeichnet) für statistische oder Marketingzwecke einsetzen. Durch die „Pixel-Tags" können
                Informationen, wie etwa der Besucherverkehr auf den Seiten dieser Website, ausgewertet werden. Die
                pseudonymen Informationen können darüber hinaus in Cookies auf dem Gerät der Nutzer gespeichert werden
                und unter anderem technische Auskünfte zum Browser und zum Betriebssystem, zu verweisenden Websites, zur
                Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebots enthalten, aber auch mit solchen
                Informationen aus anderen Quellen verbunden werden.</p>
            <p><strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer um ihre Einwilligung in den Einsatz
                der Drittanbieter bitten, stellt die Rechtsgrundlage der Datenverarbeitung die Erlaubnis dar. Ansonsten
                werden die Nutzerdaten auf Grundlage unserer berechtigten Interessen (d. h. Interesse an effizienten,
                wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang möchten wir
                Sie auch auf die Informationen zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.</p>
            <ul className="m-elements">
                <li><strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer,
                    Klickpfade, Nutzungsintensität und -frequenz, verwendete Gerätetypen und Betriebssysteme,
                    Interaktionen mit Inhalten und Funktionen); Meta-, Kommunikations- und Verfahrensdaten (z. B.
                    IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte Personen). Standortdaten (Angaben zur
                    geografischen Position eines Gerätes oder einer Person).
                </li>
                <li><strong>Betroffene Personen:</strong> Nutzer (z. B. Webseitenbesucher, Nutzer von Onlinediensten).
                </li>
                <li><strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und
                    Nutzerfreundlichkeit.
                </li>
                <li><strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend Angaben im Abschnitt "Allgemeine
                    Informationen zur Datenspeicherung und Löschung". Speicherung von Cookies von bis zu 2 Jahren
                    (Sofern nicht anders angegeben, können Cookies und ähnliche Speichermethoden für einen Zeitraum von
                    zwei Jahren auf den Geräten der Nutzer gespeichert werden.).
                </li>
                <li className=""><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
                    Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                </li>
            </ul>
            <p><strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong></p>
            <ul className="m-elements">
                <li><strong>Einbindung von Drittsoftware, Skripten oder Frameworks (z. B. jQuery): </strong>Wir binden
                    in unser Onlineangebot Software ein, die wir von Servern anderer Anbieter abrufen (z. B.
                    Funktions-Bibliotheken, die wir zwecks Darstellung oder Nutzerfreundlichkeit unseres Onlineangebotes
                    verwenden). Hierbei erheben die jeweiligen Anbieter die IP-Adresse der Nutzer und können diese zu
                    Zwecken der Übermittlung der Software an den Browser der Nutzer sowie zu Zwecken der Sicherheit, als
                    auch zur Auswertung und Optimierung ihres Angebotes verarbeiten. - Wir binden in unser Onlineangebot
                    Software ein, die wir von Servern anderer Anbieter abrufen (z. B. Funktions-Bibliotheken, die wir
                    zwecks Darstellung oder Nutzerfreundlichkeit unseres Onlineangebotes verwenden). Hierbei erheben die
                    jeweiligen Anbieter die IP-Adresse der Nutzer und können diese zu Zwecken der Übermittlung der
                    Software an den Browser der Nutzer sowie zu Zwecken der Sicherheit, als auch zur Auswertung und
                    Optimierung ihres Angebotes verarbeiten; <span className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).</span>
                </li>
                <li><strong>Google Fonts (Bezug vom Google Server): </strong>Bezug von Schriften (und Symbolen) zum
                    Zwecke einer technisch sicheren, wartungsfreien und effizienten Nutzung von Schriften und Symbolen
                    im Hinblick auf Aktualität und Ladezeiten, deren einheitliche Darstellung und Berücksichtigung
                    möglicher lizenzrechtlicher Beschränkungen. Dem Anbieter der Schriftarten wird die IP-Adresse des
                    Nutzers mitgeteilt, damit die Schriftarten im Browser des Nutzers zur Verfügung gestellt werden
                    können. Darüber hinaus werden technische Daten (Spracheinstellungen, Bildschirmauflösung,
                    Betriebssystem, verwendete Hardware) übermittelt, die für die Bereitstellung der Schriften in
                    Abhängigkeit von den verwendeten Geräten und der technischen Umgebung notwendig sind. Diese Daten
                    können auf einem Server des Anbieters der Schriftarten in den USA verarbeitet werden - Beim Besuch
                    unseres Onlineangebotes senden die Browser der Nutzer ihre Browser HTTP-Anfragen an die Google Fonts
                    Web API (d. h. eine Softwareschnittstelle für den Abruf der Schriftarten). Die Google Fonts Web API
                    stellt den Nutzern die Cascading Style Sheets (CSS) von Google Fonts und danach die in der CCS
                    angegebenen Schriftarten zur Verfügung. Zu diesen HTTP-Anfragen gehören (1) die vom jeweiligen
                    Nutzer für den Zugriff auf das Internet verwendete IP-Adresse, (2) die angeforderte URL auf dem
                    Google-Server und (3) die HTTP-Header, einschließlich des User-Agents, der die Browser- und
                    Betriebssystemversionen der Websitebesucher beschreibt, sowie die Verweis-URL (d. h. die Webseite,
                    auf der die Google-Schriftart angezeigt werden soll). IP-Adressen werden weder auf Google-Servern
                    protokolliert noch gespeichert und sie werden nicht analysiert. Die Google Fonts Web API
                    protokolliert Details der HTTP-Anfragen (angeforderte URL, User-Agent und Verweis-URL). Der Zugriff
                    auf diese Daten ist eingeschränkt und streng kontrolliert. Die angeforderte URL identifiziert die
                    Schriftfamilien, für die der Nutzer Schriftarten laden möchte. Diese Daten werden protokolliert,
                    damit Google bestimmen kann, wie oft eine bestimmte Schriftfamilie angefordert wird. Bei der Google
                    Fonts Web API muss der User-Agent die Schriftart anpassen, die für den jeweiligen Browsertyp
                    generiert wird. Der User-Agent wird in erster Linie zum Debugging protokolliert und verwendet, um
                    aggregierte Nutzungsstatistiken zu generieren, mit denen die Beliebtheit von Schriftfamilien
                    gemessen wird. Diese zusammengefassten Nutzungsstatistiken werden auf der Seite „Analysen" von
                    Google Fonts veröffentlicht. Schließlich wird die Verweis-URL protokolliert, sodass die Daten für
                    die Wartung der Produktion verwendet und ein aggregierter Bericht zu den Top-Integrationen basierend
                    auf der Anzahl der Schriftartenanfragen generiert werden kann. Google verwendet laut eigener
                    Auskunft keine der von Google Fonts erfassten Informationen, um Profile von Endnutzern zu erstellen
                    oder zielgerichtete Anzeigen zu schalten; <strong>Dienstanbieter:</strong> Google Ireland Limited,
                    Gordon House, Barrow Street, Dublin 4, Irland; <span className=""><strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); </span><strong>Website:</strong>
                    <a href="https://fonts.google.com/"
                       target="_blank">https://fonts.google.com/</a>; <strong>Datenschutzerklärung:</strong> <a
                        href="https://policies.google.com/privacy"
                        target="_blank">https://policies.google.com/privacy</a>; <strong>Grundlage
                        Drittlandtransfers:</strong> Data Privacy Framework (DPF). <strong>Weitere
                        Informationen:</strong> <a href="https://developers.google.com/fonts/faq/privacy?hl=de"
                                                   target="_blank">https://developers.google.com/fonts/faq/privacy?hl=de</a>.
                </li>
                <li><strong>Google Maps: </strong>Wir binden die Landkarten des Dienstes "Google Maps" des Anbieters
                    Google ein. Zu den verarbeiteten Daten können insbesondere IP-Adressen und Standortdaten der Nutzer
                    gehören; <strong>Dienstanbieter:</strong> Google Cloud EMEA Limited, 70 Sir John Rogerson’s Quay,
                    Dublin 2, Irland; <span className=""><strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); </span><strong>Website:</strong>
                    <a href="https://mapsplatform.google.com/"
                       target="_blank">https://mapsplatform.google.com/</a>; <strong>Datenschutzerklärung:</strong> <a
                        href="https://policies.google.com/privacy"
                        target="_blank">https://policies.google.com/privacy</a>. <strong>Grundlage
                        Drittlandtransfers:</strong> Data Privacy Framework (DPF).
                </li>
            </ul>
            <p className="seal"><a href="https://datenschutz-generator.de/"
                                   title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                                   target="_blank" rel="noopener noreferrer nofollow">Erstellt mit kostenlosem
                Datenschutz-Generator.de von Dr. Thomas Schwenke</a></p>

        </div>
    );
}

export default Datenschutz;
