import headerimage from './assets/turtle1.webp';
import redPin from './assets/redPin.svg';
import greenPin from './assets/greenPin.svg';
import bluePin from './assets/bluePin.svg';
import purplePin from './assets/purplePin.svg';
import greyPin from './assets/greyPin.svg';
import yellowPin from './assets/yellowPin.svg';
import schildi from './assets/photo_2024-07-26_15-33-26.webp'
import './App.css';
import Button from './components/Button';
import Footer from "./components/Footer.js";
import ContentSection from "./components/ContentSection";
import Header from "./components/Header";
import {useInView} from 'react-intersection-observer';
import React, {useState, useEffect, useMemo} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import Kontakt from "./pages/Kontakt";
import Hilfe from "./pages/Hilfe";
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';


const IntroContent = () => {
    const staticText = 'Schildkröte';
    const dynamicTexts = useMemo(() => [
        'entlaufen?',
        'gefunden?',
        'vermisst?'
    ], []);


    const [currentText, setCurrentText] = useState(dynamicTexts[0]);
    const [textIndex, setTextIndex] = useState(0);
    const [fadeClass, setFadeClass] = useState('fade-in');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setFadeClass('fade-out');
            setTimeout(() => {
                setTextIndex(prevIndex => (prevIndex + 1) % dynamicTexts.length);
                setFadeClass('fade-in');
            }, 1000); // Match this to the fade-out duration
        }, 4000); // Change text every 4 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [dynamicTexts.length]);

    useEffect(() => {
        setCurrentText(dynamicTexts[textIndex]);
    }, [textIndex, dynamicTexts]);

    return (
        <main className="main-content">
            <div className="intro content-padding">
                <h1>
                    {staticText} <span className={fadeClass}>{currentText}</span>
                </h1>
                <p className="subtitle-color-detail">
                    Europaweite Such- und Fundmeldungen von Wasser- und Landschildkröten
                </p>
                <div className={"spacer-30"}></div>
                <Button link="/kontakt" text="Meldung erstellen  →"/>
            </div>

            <img className="image padding-top" src={headerimage} alt="Schildkröte die auf Gras sitzt und nach links schaut"/>
        </main>
    );
}


const CountUp = ({endValue}) => {
    const [count, setCount] = useState(0);
    const duration = 1; // Duration in seconds

    useEffect(() => {
        if (endValue === 0) return;

        let start = 0;
        const end = endValue;
        const increment = end / (duration * 1000 / 100);
        const interval = setInterval(() => {
            start += increment;
            if (start >= end) {
                setCount(end);
                clearInterval(interval);
            } else {
                setCount(Math.floor(start));
            }
        }, 100);

        return () => clearInterval(interval);
    }, [endValue]);

    return <h2>{count}+</h2>;
};

const StatsSection = () => {
    const {ref, inView} = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    return (
        <section className="stats-section" ref={ref}>
            <div className="stats-overlay">
                <div className="stat">
                    {inView && <CountUp endValue={10500}/>}
                    <p>Fundmeldungen</p>
                </div>
                <div className="stat">
                    {inView && <CountUp endValue={6800}/>}
                    <p>Schildkröten nach Hause gebracht</p>
                </div>
                <div className="stat">
                    {inView && <CountUp endValue={1300}/>}
                    <p>Mitglieder</p>
                </div>
            </div>
        </section>
    );
};

const MapSection = () => {
    return (
        <section className="map-section content-padding">
            <h2>Karte der Such- und Fundtiere 2024</h2>
            <div className="iframe-container">
                <iframe
                    loading="lazy"
                    title="Google Map"
                    src="https://www.google.com/maps/d/embed?mid=1SmiMY18ShXUnWTuYBcUFWuIaVKbKOwk&ehbc=2E312F"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    style={{border: 0}}
                    allowFullScreen
                ></iframe>
            </div>
        </section>
    );
}

const MapLegend = () => {
    return (
        <div>
            <section className="map-legend">
                <div className="legend-item">
                    <img src={redPin} alt="Roter Pin" className="legend-icon"/>
                    <span className="legend-text">Vermisst</span>
                </div>
                <div className="legend-item">
                    <img src={greenPin} alt="Grüner Pin" className="legend-icon"/>
                    <span className="legend-text">Gefunden</span>
                </div>
                <div className="legend-item">
                    <img src={bluePin} alt="Blauer Pin" className="legend-icon"/>
                    <span className="legend-text">Happy End</span>
                </div>
                <div className="legend-item">
                    <img src={purplePin} alt="Lila Pin" className="legend-icon"/>
                    <span className="legend-text">Auffangstationen</span>
                </div>
                <div className="legend-item">
                    <img src={greyPin} alt="Grauer Pin" className="legend-icon"/>
                    <span className="legend-text">Sonstige Exoten</span>
                </div>
                <div className="legend-item">
                    <img src={yellowPin} alt="Gelber Pin" className="legend-icon"/>
                    <span className="legend-text">Gestohlen</span>
                </div>
            </section>
            <div className={"spacer-30"}></div>
        </div>
    );
}

const Divider = () => {
    return (
        <section className="divider-section">
        </section>
    );
}

function Home() {
    return (
        <div className={"resp-container"}>
            <IntroContent/>
            <div className="spacer-150"/>
            <StatsSection/>
            <MapSection/>
            <MapLegend/>
            <Divider/>
            <ContentSection
                imageSrc={schildi}
                heading="Über uns"
                alt="Eine Schildköte von hinten"
                paragraphs={[
                    "Wir sind mehr als ein nur ein Haufen Schildkrötenfreunde: Bei uns arbeiten 15 engagierte Ehrenamtliche in verschiedenen Bereichen (Google Maps, Moderation, VK, Instagram, Anzeigen und Website) eng zusammen, um entlaufene und gefundene Schildkröten aus dem deutschsprachigen Europa wieder nach Hause zu bringen. Hinzu kommt noch unser wertvolles Team von Artbestimmung-Experten. ",
                    "Im Hochsommer kommen gelegentlich 70 Fund- und Suchmeldungen an einem Tag herein. Gemeinsam stecken wir viel Freude und Leidenschaft in unser Projekt. Über 6000 erfolgreich nach Hause gebrachte Schildkröten sprechen für sich. Wir arbeiten eng mit Artenschutzbehörden, Veterinärämtern, Auffangstationen, Pflegestellen, Tierheimen, Reptilientierärzten  und engagierten Haltern zusammen, um Fundtiere schnell gut versorgt zu wissen."
                ]}
                buttons={[
                    {link: "https://bit.ly/Schildisuche ", text: "zur Facebook Gruppe"},
                    {link: "mailto:kontakt@schildkroetensuche.org", text: "E-Mail senden"}
                ]}
                imageRight={true}
            />
        </div>
    );
}

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

function App() {
    return (
        <Router>
            <ScrollToTop/>
            <Header/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/kontakt" element={<Kontakt/>}/>
                <Route path="/hilfe" element={<Hilfe/>}/>
                <Route path="/impressum" element={<Impressum/>}/>
                <Route path="/datenschutz" element={<Datenschutz/>}/>
            </Routes>
            <Footer/>
        </Router>
    );
}


export default App;
