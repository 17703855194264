import logo from "../assets/neueslogo.svg";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Header.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="header">
            <div className="logo">
                <Link to="/">
                    <img src={logo} alt="Logo"/>
                </Link>
            </div>
            <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
            <nav className={`nav ${isOpen ? 'open' : ''}`}>
                <ul>
                    <li className="menu-item"><Link to="/" onClick={toggleMenu}>Home</Link></li>
                    <li className="menu-item"><Link to="/hilfe" onClick={toggleMenu}>Was tun?</Link></li>
                    <li className="menu-item"><Link to="/kontakt" onClick={toggleMenu}>Kontakt</Link></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
