// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import facebook from '../assets/fb.webp';
import insta from '../assets/ig.webp';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-links">
                <Link to="/impressum">Impressum</Link>
                <span>|</span>
                <Link to="/datenschutz">Datenschutz</Link>
                <span>|</span>
                <span>Copyright 2024</span>
            </div>
            <div className="footer-social">
                <a href="https://bit.ly/Schildisuche " target="_blank" rel="noopener noreferrer">
                    <img src={facebook} alt="Facebook" />
                </a>
                <a href="https://www.instagram.com/schildkroetensuche" target="_blank" rel="noopener noreferrer">
                    <img src={insta} alt="Instagram" />
                </a>
            </div>
        </footer>
    );
};

export default Footer;
