// Impressum.js
import React from "react";

const Impressum = () => {
    return (
        <div className="content-padding fullsize-content">
            <h1>Impressum</h1>
            <p>Angaben gemäß § 5 DDG</p>
            <p>Frauke Hustinx <br/>
                Dieselstraße 4 <br/>
                38122 Braunschweig
            </p>
            <p><strong>Vertreten durch:</strong>  <br/>
                Frauke Hustinx
            </p>

            <p><strong>Kontakt:</strong> <br/>
                E-Mail: kontakt@schildkroetensuche.org</p>


        </div>
    );
}

export default Impressum;
